function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export var userSlice = createSlice({
  name: "user",
  initialState: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
    userid: null,
    firsname: '',
    lastname: '',
    jobprofile: '',
    mobile: '',
    employed: '',
    avatar: '',
    email: '',
    status: '',
    jobnanme: ''
  }, "email", ""), "isUserFetching", false), "isLoginSuccess", false), "isLoginError", false), "errorMessage", ""),
  reducers: {
    // Reducer comes here
    clearState: function clearState(state) {
      state.isLoginError = false;
      state.isLoginSuccess = false;
      state.isUserFetching = false;
      return state;
    }
  },
  extraReducers: {
    // Extra reducer comes here
    /*[loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload.email;
      state.firstname = payload.firstname;
      state.lastname = payload.lastname;
      state.mobile = payload.mobile;
      state.jobnanme = payload.jobnanme;
      state.jobprofile= payload.jobprofile;
      state.employed = payload.employed;
      state.status = payload.status;
      state.avatar = payload.avatar;  
      state.userid = payload.id;
       state.isUserFetching = false;
      state.isLoginSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log('payload', payload);
      state.isUserFetching = false;
      state.isLoginError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isUserFetching = true;
    },*/
  }
});
export var userSelector = function userSelector(state) {
  return state.user;
};